<template>
  <div>
    <div class="WoShopone-header">
      <p class="header-one" @click="$router.go(-1)">
        <van-icon name="arrow-left" size="24px" />
      </p>
      <p class="header-two">我的小店</p>
      <p class="header-tress" @click="refresh">
        <van-icon name="replay" size="24" />
      </p>
    </div>
    <div class="WoShopone-center">
      <div class="center-one" @click="rightJump">
        <div class="center-one-pell">
          <p>
            <van-image
              round
              width="4rem"
              height="4rem"
              :src="imgUrl"
              style="margin-right: 10px"
            />
          </p>
          <p>{{ name }}</p>
        </div>
        <p class="center-one-poll">
          <van-icon name="arrow" size="24" />
        </p>
      </div>
      <van-grid :column-num="3">
        <van-grid-item v-for="(item, index) in WoShoptypelist" :key="index">
          <p class="center-text">
            <span>{{ item.value }}</span>
            <span>{{ item.title }}</span>
          </p>
        </van-grid-item>
      </van-grid>
      <div class="center-Number">
        <van-cell title="商品管理" icon="shop-o" @click="shareShop">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #right-icon>
            <van-icon name="arrow" class="search-icon" />
          </template>
        </van-cell>
        <van-cell title="分享小店" icon="share-o" @click="show = true">
          <template #right-icon>
            <van-icon name="arrow" class="search-icon" />
          </template>
        </van-cell>
      </div>
    </div>

    <van-popup v-model="show" position="bottom" :style="{ height: '130px' }">
      <div class="fz18 color333" style="text-align: center; margin: 15px">
        分享小店
      </div>
      <div class="share-box">
        <div class="share-item" @click="share('0')">
          <img src="/img/weixin.png" alt="" />
          <p style="line-height: 30px">微信</p>
        </div>
        <div class="share-item" @click="share('1')">
          <img src="/img/pengyouquan.png" alt="" />
          <p style="line-height: 30px">朋友圈</p>
        </div>
        <div class="share-item" @click="share('2')">
          <img src="/img/qq.png" alt="" />
          <p style="line-height: 30px">QQ</p>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { getWoShoplist } from "@/api/WoShopone/WoShopone";
import { Toast } from "vant";
// import { mapState } from 'vuex'
export default {
  name: "WoShopone",

  data() {
    return {
      name: null,
      list: [],
      imgUrl: "",
      WoShoptypelist: [],
      id: "",
      userId: "",
      show: false,
    };
  },

   mounted() {
    //定义全局session
    if (this.$route.query.session != null) {
      this.session = this.$route.query.session;
      //设置session
      this.$store.commit("setSession", this.$route.query.session);
    }
  },
  created() {
    if (this.$route.query.id != null) {
      this.userId = this.$route.query.id;
    }
    this.getWoShop();
  },
  methods: {
    refresh() {
      this.getWoShop();
    },
    getWoShop() {
      getWoShoplist().then((res) => {
        if (res.data.code == 0) {
          this.name = res.data.data.name;
          this.imgUrl = res.data.data.imgUrl;
          this.WoShoptypelist = [
            { title: "今日订单(笔)", value: res.data.data.todayOrderTotal },
            { title: "今日销售(笔)", value: res.data.data.todayOrderPayTotal },
            { title: "今日营收(笔)", value: res.data.data.todayEarn },
            { title: "累计订单(笔)", value: res.data.data.allOrderTotal },
            { title: "累计销售(笔)", value: res.data.data.allPay },
            { title: "累计营收(笔)", value: res.data.data.allEarn },
          ];
          this.id = res.data.data.shopId;
          this.userId = res.data.data.userId;
          console.log(res);
        } else {
          Toast(res.data.msg);
        }
      });
    },
    shareShop() {
      this.$router.push({
        path: "commodity",
        query: {
          userId: this.userId,
        },
      });
    },
    rightJump() {
      this.$router.push({ path: "Settinger" });
    },
    share(type) {
      console.log(type);
      this.show = false;
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

      let obj = {
        title: "我的小店",
        picUrl: this.imgUrl,
        content: this.name + "小店",
        openUrl:
          "http://192.168.0.183:8080/#/shareShop?id=" +
          this.userId +
          "&WoSession=" +
          this.$store.state.session,
        platformId: type,
      };

      console.log(obj.openUrl);
      
      if (isIOS) {
        window.webkit.messageHandlers.share.postMessage(JSON.stringify(obj));
      }
      if (isAndroid) {
        window.android.share(
          obj.platformId,
          "3",
          obj.title,
          obj.content,
          obj.openUrl,
          obj.picUrl
        );
      }
    },
  },
};
</script>
<style lang="less" scoped>
.WoShopone-header {
  position: fixed;
  background-color: #fff;
  left: 0;
  right: 0;
  height: 60px;
  // border: 1px solid #fff;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  .header-one {
    color: #000000;
    font-weight: bold;
    padding: 20px 0 0 11px;
  }
  .header-two {
    color: #000000;
    font-size: 17px;
    font-weight: bold;
    padding-top: 20px;
  }
  .header-tress {
    color: #000000;
    padding: 20px 11px 0 0;
    font-weight: bold;
  }
}
.WoShopone-center {
  padding-top: 64px;
  height: 80px;
  // border: 1px solid red;
  background-color: #fff;
  .center-one {
    display: flex;
    // margin-top: 44px;
    height: 80px;
    // border: 1px solid red;
    justify-content: space-between;
    padding: 0 19px 0 16px;
    .center-one-pell {
      font-size: 16px;
      display: flex;
      // border: 1px solid red;
      justify-content: space-between;
      
      :first-child {
         display: flex;
       flex-direction: column;
       justify-content: center;
      }
      
      :last-child {
        display: flex;
       flex-direction: column;
       justify-content: center;
      }
    }
    .center-one-poll {
      display: flex;
       flex-direction: column;
       justify-content: center;
      // line-height: 80px;
    }
  }
}
.center-text {
  display: flex;
  flex-direction: column;
  :first-child {
    font-weight: bold;
    text-align: center;
  }
  :last-child{
    font-size: 10px;
  }
}
.center-Number {
  margin-top: 10px;
  .van-icon{
    line-height: 25px;
  }
}
.share-box {
  display: flex;
  .share-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 60px;
    img {
      width: 32px;
    }
  }
}
</style>